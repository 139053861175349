.user {
  #{--nav-user-background}: #{$user-background-color};
  #{--nav-user-color}: var(--text-alt-color); // sass-lint:disable-line no-duplicate-properties

  display: flex;
  align-items: center;
  transition: background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
  border-radius: var(--border-radius);
  background: var(--nav-user-background);
  // NOTE: Itt nem kéne a disable, de a sass-lint nem okos, ha CSS változókról van szó
  padding: var(--g-xxxs) var(--g-xs); // sass-lint:disable-line shorthand-values
  color: var(--nav-user-color);

  span {
    line-height: 1;
  }

  &__name {
    display: block;
    text-align: right;
    font-size: var(--body-fs);
  }

  &__credits {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--annotation-fs);

    .no-mobile {
      margin-left: var(--g-xxxs);
    }
  }

  &__icons {
    margin-left: var(--g-s);
    border: var(--border-width) solid var(--text-alt-color);
    border-radius: 100%;
    background: $toggle-selected-color;
    width: calc(var(--icon-l-diameter) + (2 * var(--border-width)));
    height: calc(var(--icon-l-diameter) + (2 * var(--border-width)));
    overflow: hidden;
    color: var(--text-color);

    .icon {
      transition: transform var(--transition-duration) ease-in-out;
    }
  }

  &:focus,
  &:hover {
    #{--nav-user-background}: var(--text-alt-color);
    #{--nav-user-color}: var(--text-color); // sass-lint:disable-line no-duplicate-properties

    .user {
      &__icons {
        .icon {
          transform: translateY(-100%);
        }
      }
    }
  }
}
