.user-popup.f {
  max-width: 20rem;

  .use-gravity-as {
    li {
      cursor: pointer;

      &:hover{
        background: #f1f6ff;
      }
    }
  }

  .you-have {
    > * {
      display: inline-block;
    }
  }
}
