body {
  font-family: Helvetica, sans-serif;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  --border-color: var(--field-border-color);
  --background-color: var(--field-background-color);
  --height: var(--field-height);
  --color: var(--field-color);
  position: relative;
  transition: border-color var(--transition-duration) ease-in-out;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background: var(--background-color);
  height: var(--height);
  color: var(--color);
  box-sizing: border-box;
  padding-right: var(--g-xs);
  padding-left: var(--g-xs);
  font-family: Arial;
  font-size: 14px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 10;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.country-lead {
  font-size: 12px;
}

.terms-and-conditions {
  max-height: 450px;
  overflow: auto;
}