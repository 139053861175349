.required-star {
    color: var(--error-color);
}

.currency {
    display: flex;
    flex-direction: column;
    width: 250px;
    .row {
        display: flex;
        align-items: center;
        padding-bottom: 5px;
        .col {
            flex: 1;
            &.first {
                flex: 0 0 5em;
            }

            &.multiplier {
                display: flex;

                label {
                    padding-right: 5px;
                }

                .switch__label {
                    padding-top: 5px;
                }
            }
        }
    }
}

@include break-at("mobile") {
    .currency {
        width: 100%;
    }
}
