// -----------------------------------------------------------------------------
// Typography styles
// -----------------------------------------------------------------------------

body {
  line-height: 1.25;
  color: var(--text-color);
  font-family: $text-font-stack;
  font-size: var(--body-fs);
}

.title {
  &-xl {
    font-size: var(--title-xl-fs);
    font-weight: var(--fw-b);
  }

  &-l {
    font-size: var(--title-l-fs);
    font-weight: var(--fw-b);
  }

  &-m {
    font-size: var(--title-m-fs);
    font-weight: var(--fw-sb);
  }

  &-s {
    font-size: var(--title-s-fs);
    font-weight: var(--fw-sb);
  }
}

.selection {
  font-size: $selection-fs;
  font-weight: var(--fw-b);
}

a {
  color: $link-color;

  &:hover {
    text-decoration: none;
  }
}

.annotation,
.hint,
.error {
  font-size: var(--annotation-fs);
  font-weight: var(--fw-l);
}

.label {
  font-size: var(--body-fs);
}

.fw {
  &-l   { font-weight: var(--fw-l); }
  &-n   { font-weight: var(--fw-n); }
  &-sb  { font-weight: var(--fw-sb); }
  &-b   { font-weight: var(--fw-b); }
  &-el   { font-weight: var(--fw-el); }
}

.ta {
  &-r { text-align: right; }
}
