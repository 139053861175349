.icon {
  #{--diameter}: var(--icon-diameter);

  display: block;
  width: var(--diameter);
  height: var(--diameter);

  &--s {
    #{--diameter}: var(--icon-s-diameter);
  }

  &--l {
    #{--diameter}: var(--icon-l-diameter);
  }
}
