body {
  &[menuopen] {
    nav {
      opacity: 0.3;
    }
    .main {
      opacity: 0.3;
    }
  }

}

.menu {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: stretch;
  transform: translateX(-100%);
  transition: transform var(--transition-duration) ease-in-out var(--transition-duration);
  z-index: 1100;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color: var(--text-alt-color);

  a {
    text-decoration: none;
    color: inherit;
  }

  &__inner,
  &__close {
    box-shadow: var(--menu-box-shadow);
  }

  &__inner {
    #{--inner-width}: 80%;
    display: flex;
    flex: 0 0 var(--inner-width);
    flex-direction: column;
    background: var(--text-color);
    width: var(--inner-width);

    > .stack {
      margin: var(--g);
    }
  }

  &__close {
    display: flex;
    flex: 0 0 var(--g-xl);
    align-items: center;
    justify-content: center;
    transition: opacity var(--transition-duration) ease-in-out calc(2 * var(--transition-duration));
    opacity: 0;
    margin: var(--g) 0 0 var(--g);
    border-radius: 100%;
    background: var(--text-color);
    width: var(--g-xl);
    height: var(--g-xl);
  }

  &[open] {
    transform: translateX(0);

    .menu__close {
      opacity: 1;
    }
  }
}

@include break-at('mobile') {
  .menu {
    &__close {
      flex: 0 0 var(--g-l);
      width: var(--g-l);
      height: var(--g-l);
    }
  }
}
