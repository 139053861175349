// -----------------------------------------------------------------------------------
// This file contains all styles related to the stack layout of the site/application.
// -----------------------------------------------------------------------------------

[class^='stack'] > * {
  margin-top: 0;
  margin-bottom: 0;
}

[class^='h-stack'] > * {
  margin-right: 0;
  margin-left: 0;
}

.stack {
  #{--space}: 0;
  flex-direction: column;
  justify-content: flex-start;

  > * + * {
    margin-top: var(--space);
  }

  > [hidden],
  > [hidden] + *,
  > .visually-hidden,
  > .visually-hidden + * {
    margin-top: 0;
  }
}

.h-stack {
  #{--space}: 0;

  > * + * {
    margin-left: var(--space);
  }

  > [hidden],
  > [hidden] + *,
  > .visually-hidden,
  > .visually-hidden + * {
    margin-left: 0;
  }
}

.stack,
.h-stack {
  display: flex;
  list-style: none;

  &-xxxs {
    #{--space}: var(--g-xxxs);
  }

  &-xxs {
    #{--space}: var(--g-xxs);
  }

  &-xs {
    #{--space}: var(--g-xs);
  }

  &-s {
    #{--space}: var(--g-s);
  }

  &-m {
    #{--space}: var(--g);
  }

  &-l {
    #{--space}: var(--g-l);
  }

  &-xl {
    #{--space}: var(--g-xl);
  }
}

/// -----------------------------------------------------------------------------
/// Fill available context in a stack
/// -----------------------------------------------------------------------------
.fill {
  flex: 1;
}

/// -----------------------------------------------------------------------------
/// Align items helpers
/// -----------------------------------------------------------------------------
.ai {
  &-fs {
    align-items: flex-start;
  }

  &-c {
    align-items: center;
  }

  &-fe {
    align-items: flex-end;
  }

  &-s {
    align-items: stretch;
  }
}

/// -----------------------------------------------------------------------------
/// Justify content helpers
/// -----------------------------------------------------------------------------
.jc {
  &-fs {
    justify-content: flex-start;
  }

  &-c {
    justify-content: center;
  }

  &-fe {
    justify-content: flex-end;
  }

  &-sb {
    justify-content: space-between;
  }

  &-sa {
    justify-content: space-around;
  }
}

/// -----------------------------------------------------------------------------
/// Aligns item to the opposite side of the container
/// -----------------------------------------------------------------------------
.split {
  &-top {
    margin-bottom: auto !important; // sass-lint:disable-line no-important
  }

  &-right {
    margin-left: auto !important; // sass-lint:disable-line no-important
  }

  &-bottom {
    margin-top: auto !important; // sass-lint:disable-line no-important
  }

  &-left {
    margin-right: auto !important; // sass-lint:disable-line no-important
  }
}

/// -----------------------------------------------------------------------------
/// Tablet helpers
/// -----------------------------------------------------------------------------
@include break-at('tablet') {
  .t-fd-c {
    flex-direction: column;

    > * + * {
      margin-top: var(--space);
      margin-left: 0 !important; // sass-lint:disable-line no-important
    }
  }
}

/// -----------------------------------------------------------------------------
/// Mobile helpers
/// -----------------------------------------------------------------------------
@include break-at('mobile') {
  .m-fd-r {
    flex-direction: row;
    flex-wrap: nowrap;

    > * + * {
      margin-top: 0 !important; // sass-lint:disable-line no-important
      margin-left: var(--space);
    }
  }

  .m-fd-c {
    flex-direction: column;

    > * + * {
      margin-top: var(--space);
      margin-left: 0 !important; // sass-lint:disable-line no-important
    }
  }

  .m-fd-cr {
    flex-direction: column-reverse;

    > * {
      &:nth-child(1) {
        margin-top: var(--space);
      }

      &:last-child {
        margin-top: 0;
      }
    }

    > * + * {
      margin-top: var(--space);
      margin-left: 0 !important; // sass-lint:disable-line no-important
    }
  }
}
