.fullpage-loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    background: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;

    > div {
        width: 100%;
        text-align: center;
    }
}

.popup-dialog,
.register-popup-dialog {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;

    background: rgba(0, 0, 0, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    > div {
        width: 35%;
    }
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.company-list-item {
    cursor: pointer;
}

.marginated {
    margin-top: 25px;
    margin-bottom: 25px;
}

.cart-flex {
    display: flex;
}

body {
    &.popup,
    &.register-popup {
        overflow: hidden;
    }

    &.popup {
        .popup-dialog {
            display: flex;
        }
    }

    .popup-dialog {
        display: none;
    }
}

.attribute_source {
    display: flex;
    align-items: center;
    img {
        width: 20px;
        cursor: pointer;
    }
}

.attachment-list {
    display: flex;

    .item {
        width: 100%;
    }
}

.upload-batch {
    margin-top: 10px;
    font-size: 14px;
}

.workspace-confirmation-table {
    border-left: 1px solid black;
    
    .title {
        font-weight: bold;
    }

    .grid {
        grid-gap: initial;
    }

    .row {
        border-top: 1px solid black;

        &:last-child {
            border-bottom: 1px solid black;
        }
    }

    .column {
        border-right: 1px solid black;
        padding: 5px;
    }
}

.font-16 {
    font-size: 16px;
}