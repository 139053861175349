.switch {
  #{--switch-border-radius}: var(--border-radius);
  #{--switch-width}: auto; // sass-lint:disable-line no-duplicate-properties
  #{--switch-height}: auto; // sass-lint:disable-line no-duplicate-properties
  #{--border-color}: var(--field-border-color); // sass-lint:disable-line no-duplicate-properties
  #{--background-color}: var(--field-background-color); // sass-lint:disable-line no-duplicate-properties

  display: flex;
  align-items: center;
  width: var(--switch-width);
  // height: var(--switch-height);

  &__switch {
    display: block;
    position: relative;
    flex: 0 0 var(--switch-width);
    transition: border-color var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out;
    border: var(--border-width) solid var(--border-color);
    border-radius: var(--switch-border-radius);
    background: var(--background-color);
    cursor: pointer;
    width: var(--switch-width);
    height: var(--switch-height);

    .dot {
      position: absolute;
      top: 0;
      transition: opacity var(--transition-duration) ease-in-out, transform var(--transition-duration) ease-in-out;
      border-radius: var(--switch-border-radius);
    }
  }

  &__label {
    line-height: var(--g);
  }

  &[disabled] {
    #{--border-color}: var(--disabled-color);
    #{--background-color}:  var(--field-disabled-background-color); // sass-lint:disable-line no-duplicate-properties

    opacity: 0.75;
    cursor: not-allowed;
    pointer-events: none;
  }

  &[error] {
    #{--border-color}: var(--error-color);
    #{--background-color}:  var(--error-background-color); // sass-lint:disable-line no-duplicate-properties
  }

  &--checkbox,
  &--radio {
    #{--switch-width}: #{$switch-radio-checkbox-diameter};
    #{--switch-height}: #{$switch-radio-checkbox-diameter}; // sass-lint:disable-line no-duplicate-properties
  }

  &--checkbox {
    .switch__switch {
      color: var(--text-alt-color);

      .dot {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotateZ(-45deg);
        opacity: 0;
        border-bottom: var(--border-width) solid currentColor;
        border-left: var(--border-width) solid currentColor;
        width: var(--g-xs);
        height: var(--g-xxs);
      }
    }

    input {
      &:checked {
        + .switch__switch {
          #{--border-color}: var(--brand-color);
          #{--background-color}: var(--brand-color); // sass-lint:disable-line no-duplicate-properties

          .dot { // sass-lint:disable-line nesting-depth
            opacity: 1;
          }
        }
      }
    }
  }

  &--radio {
    #{--switch-border-radius}: 100%;

    .switch__switch {
      .dot {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        border-radius: 100%;
        background:  var(--brand-color);
        width: calc(var(--switch-width) - (4 * var(--border-width)));
        height: calc(var(--switch-width) - (4 * var(--border-width)));
      }
    }

    input {
      &:checked {
        + .switch__switch {
          .dot { // sass-lint:disable-line nesting-depth
            opacity: 1;
          }
        }
      }
    }
  }

  &--toggle {
    #{--switch-border-radius}: #{$switch-toggle-height};
    #{--switch-width}: #{$switch-toggle-width}; // sass-lint:disable-line no-duplicate-properties
    #{--switch-height}: #{$switch-toggle-height}; // sass-lint:disable-line no-duplicate-properties

    .switch__switch {
      #{--border-color}: #{$disabled-color};
      #{--background-color}: #{$disabled-color}; // sass-lint:disable-line no-duplicate-properties

      .dot {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 100%;
        background: $text-alt-color;
        width: calc(var(--switch-height) - (2 * var(--border-width)));
        height: calc(var(--switch-height) - (2 * var(--border-width)));
      }
    }

    input {
      &:checked {
        + .switch__switch {
          #{--border-color}:  var(--brand-color);
          #{--background-color}:  var(--brand-color); // sass-lint:disable-line no-duplicate-properties

          .dot { // sass-lint:disable-line nesting-depth
            transform: translateX(calc(var(--switch-width) - var(--switch-height)));
          }
        }
      }
      &:disabled {
        + .switch__switch {
          #{--border-color}: var(--disabled-color);
          #{--background-color}:  var(--field-disabled-background-color); 

          .dot { // sass-lint:disable-line nesting-depth
            transform: translateX(calc(var(--switch-width) - var(--switch-height)));
          }
        }
      }
    }

    &[disabled] {
      .switch__switch {
        .dot {
          background-color: var(--field-disabled-background-color);
        }
      }
    }

    &[error] {
      .switch__switch {
        #{--border-color}: var(--error-color);
        #{--background-color}:  var(--error-background-color); // sass-lint:disable-line no-duplicate-properties
      }
    }
  }

  &--label-left,
  &--label-right {
    width: auto;
  }

  &--label-left {
    .switch__switch {
      order: 2;
    }

    .switch__label {
      order: 1;
      margin-right: var(--g-xxs);
    }
  }

  &--label-right {
    .switch__switch {
      order: 1;
    }

    .switch__label {
      order: 2;
      margin-left: var(--g-xxs);
    }
  }
  &--label-multiple {
    .switch__left_label {
      order: 1;
      margin-right: var(--g-xxs);
    }

    .switch__switch {
      order: 2;
    }

    .switch__right_label {
      order: 3;
      margin-left: var(--g-xxs);
    }
  }
}
