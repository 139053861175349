.tagarea {
    .field {
        display: flex;
        flex-wrap: wrap;
        height: inherit;

        .icon-chip {
            margin: 3px 4px;
    
            svg.icon {
                margin-left: 0;
                margin-right: -3px;
            }
        }

        input {
            min-width: 100px;
        }
    }
}

.pointer {
    cursor: pointer;
}