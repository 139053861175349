.container {
  margin-right: var(--container-margin);
  margin-left: var(--container-margin);
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  padding: 50px;
}


@include break-at("mobile") {
  .popup-dialog,
  .register-popup-dialog {
      width: 100% !important;
  }
}