.search-form {
  border-radius: var(--border-radius);
  background: $search-form-background-color;
  padding: var(--g-s);
  color: var(--text-alt-color);

  a {
    color: var(--brand-color);
  }

  &__filter {
    .label {
      white-space: nowrap;
    }
    >.h-stack {
      margin-top: var(--g-xs);
    }
  }

  &--extended {
    a {

    }
  }
}

@include break-at('tablet') {
  .search-form {
    &--extended {
      .search-form__filter {
        flex-wrap: wrap;
        margin-top: var(--g);
      }

      .search-form__filter-toggles {
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--g-xs));

        > div {
          margin-top: var(--g-xs);
        }
      }

      .search-form__open {
        flex-basis: 100%;
        margin-top: var(--g);
      }
    }

    &--advanced {
      .search-form__filter-toggles {
        flex-wrap: wrap;
        margin-top: calc(-1 * var(--g-xs));

        > div {
          margin-top: var(--g-xs);
        }
      }
    }
  }
}

@include break-at('mobile') {
  .search-form {
    &--extended {
      .search-form__filter-toggles {
        margin-top: 0;

        > div {
          flex: 0 0 100%;
          margin-left: 0;
        }
      }
    }

    &--advanced {
      .search-form__filter-toggles {
        flex-wrap: wrap;
        margin-top: 0;

        > div {
          flex: 0 0 100%;
          margin-left: 0;
        }
      }
    }
  }
}
