.progress-nav {
  display: flex;
  align-items: center;
}

@include break-at('mobile') {
  .progress-nav {
    > .h-stack {
      align-items: stretch;
    }

    .annotation {
      text-align: center;
    }
  }
}
