.chip {
  display: inline-block;
  border: var(--border-width) solid var(--disabled-color);
  border-radius: var(--g-xs);
  background: var(--text-alt-color);
  padding-right: var(--g-xxs);
  padding-left: var(--g-xxs);
  height: var(--chip-height);
  text-decoration: none;
  color: var(--text-color);

  .label {
    text-decoration: none;
    line-height: calc(var(--chip-height) - (2 * var(--border-width)));
    color: inherit;
    font-size: var(--annotation-fs);
    font-weight: var(--fw-sb);
  }
}
