// --------------------------------------------------------------------------
// NOTE:
// Itt a default select működést fogom alapértelmezetten beállítani, de mivel
// a látványtervben a legördülő résznek is van designja, ezért az is
// elkészül, hogy Reactban implementálható legyen ld.: _dropdown.scss.
// --------------------------------------------------------------------------

.label + .field,
.label + .interval,
.field + .hint,
.field + .error,
.hint + .error {
  margin-top: var(--g-xxs);
}

.field {
  #{--border-color}: var(--field-border-color);
  #{--background-color}: var(--field-background-color); // sass-lint:disable-line no-duplicate-properties
  #{--height}: var(--field-height); // sass-lint:disable-line no-duplicate-properties
  #{--color}: var(--field-color); // sass-lint:disable-line no-duplicate-properties

  position: relative;
  transition: border-color var(--transition-duration) ease-in-out;
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  background: var(--background-color);
  height: var(--height);
  color: var(--color);
  box-sizing: border-box;
  font-family: Arial;

  &__field {
    position: relative;
    z-index: 2;
    border: 0;
    background: transparent;
    padding-right: var(--g-xs);
    padding-left: var(--g-xs);
    width: 100%;
    height: calc(var(--height) - (2 * var(--border-width)));
    line-height: calc(var(--height) - (2 * var(--border-width)));
    color: var(--color);
    font-family: inherit;
    font-size: 14px;
    box-sizing: border-box;

    ::placeholder {
      color: var(--field-placeholder-color);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &--left {
      left: var(--g-xxs);
    }

    &--right {
      right: var(--g-xxs);
    }
  }

  &:focus-within {
    #{--border-color}: var(--text-color);

    .field__field {
      outline: 0;
    }
  }

  [disabled] {
    color: #ffffff;
    background: var(--field-disabled-background-color);
    #{--color}: var(--field-placeholder-color); // sass-lint:disable-line no-duplicate-properties
  }

  &[error] {
    #{--border-color}: var(--error-color);
    #{--background-color}: var(--error-background-color); // sass-lint:disable-line no-duplicate-properties
  }

  &--icon-left {
    .field__field {
      padding-left: calc(var(--icon-diameter) + (2 * var(--g-xxs)));
    }
  }

  &--icon-right {
    .field__field {
      padding-right: calc(var(--icon-diameter) + (2 * var(--g-xxs)));
    }
  }

  &--multiline {
    #{--height}: var(--field-multiline-height);

    .field__field {
      padding: var(--g-xs);
      line-height: 1.4;
    }
  }

  &--select {
    .field__field {
      padding-right: calc(var(--icon-diameter) + (2 * var(--g-xxs)));
      appearance: none;

      option {
        font-family: Arial;
      }
    }
  }
}

.error {
  color: var(--error-color);
}

.interval {
  position: relative;

  .sep {
    display: block;
    width: var(--g);
    text-align: center;
  }
}
