.header-button {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

@media (max-width: 30em) {
    .header-button {
        align-items: flex-start;
    }
}

.how-it-works {
    padding-top: 2rem;

    .advantages-list {
        padding-left: 14px;
        ul {
            margin-left: 15px;
            list-style-type: disc;
            li {
                line-height: 22px;
            }
        }

        border: 1px solid #04dead;
        padding: 15px;
    }

    .credit-system-list {
        padding-left: 14px;
        ul {
            margin-left: 15px;
            list-style-type: disc;
            li {
                line-height: 22px;
            }
        }
    }

    .supporting_documents-list {
        padding-left: 14px;
        ul {
            list-style-type: disc;
            li {
                line-height: 24px;
            }
        }
    }

    .registration-list {
        padding-left: 14px;
        ul {
            list-style-type: none;
            li {
                line-height: 30px;
            }
        }
    }

    .separator {
        height: 5px;
        width: 100%;
        background-color: #04dead;
    }
    .video-container {
        overflow: hidden;
        position: relative;
        width: 100%;
    }

    .video-container::after {
        padding-top: 56.25%;
        display: block;
        content: "";
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .right-inner > * {
        margin-bottom: 30px;
    }

    .list > * {
        margin-bottom: 14px;
    }
}

.listing {
    display: flex;
    span {
        min-width: 20px;
    }
}

img[data-video] {
    cursor: pointer;
}

.specific-company-list1 {
    list-style: auto;
    padding-left: 35px;

    li {
        padding-top: 5px;
    }
}

.specific-company-list2 {
    list-style: disc;
    padding-left: 35px;
}
