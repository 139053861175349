.popup,
.register-popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;

  &__content,
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
  }

  &__popup {
    border: var(--border-width) solid var(--disabled-color);
    border-radius: var(--border-radius);
    background: var(--text-alt-color);
    padding: var(--g-xl);
    text-align: center;
  }

  &__overlay {
    opacity: 0.4;
    z-index: 1;
    background: var(--text-color);
  }

  &__credits {
    margin: auto;
    width: 4rem;
  }

  hr {
    width: 50%;
  }
}

.section--results {
  position: relative;
  z-index: 1;

  .popup {
    position: absolute;
    z-index: 1;

    &__overlay {
      opacity: 0;
    }
  }
}
