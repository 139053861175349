.stack > {
  .source-info {
    margin-top: 0;
  }
  .info-count {
    margin-bottom: 5px;
  }
}

.source {
  width: 20px;
}

.pager {
  padding: 20px 0;
  border-top: var(--border-width-thin) solid var(--disabled-color);
  border-bottom: var(--border-width-thin) solid var(--disabled-color);
}