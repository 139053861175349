.icon-button {
  display: inline-block;
  text-decoration: none;
  color: inherit;

  &__inner {
    display: flex;
    align-items: center;
  }

  .icon + .label {
    margin-left: var(--g-xxs);
  }

  .label {
    white-space: nowrap;
  }

  &:focus,
  &:hover {
    .label {
      text-decoration: underline;
    }
  }
}
