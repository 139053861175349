.faq {
    padding-top: 2rem;

    li {
        padding-bottom: 10px;
    }

    .faq-list {
        > li {
            margin-left: 14px;
            list-style: disc;

            &.level-1 {
                list-style: none;

                > ul {
                    li {
                        list-style: circle;
                        margin-left: 28px;

                        &.filled {
                            list-style: disc;
                        }
                    }
                }

                .innerList {
                    margin-top: 10px;
                }

                .decimal {
                    > li {
                        list-style: decimal;
                    }
                }
            }
        }
    }
}
