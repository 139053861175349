.tab-navigation {
  margin-top: var(--g);

  &__desktop {
    display: flex;
  }

  &__mobile {
    display: none;
  }

  .tab {
    flex: 1;
  }
}

@include break-at('mobile') {
  .tab-navigation {
    &__desktop {
      display: none;
    }

    &__mobile {
      display: flex;
      width: 100%;

      .field {
        flex: 1;
      }
    }
  }
}
