.footer {
  background: var(--text-color);
  padding-top: var(--g-xl);
  padding-bottom: var(--g-xl);
  height: var(--footer-height);
  color: var(--text-alt-color);

  a {
    text-decoration: none;
    color: inherit;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  .logo {
    #{--logo-width}: calc(1.5 * var(--logo-full-width));
    #{--logo-height}: calc(1.5 * var(--logo-full-height)); // sass-lint:disable-line no-duplicate-properties
  }
}

@include break-at('tablet') {
  .footer {
    height: auto;

    &__details {
      margin-top: var(--g-xl);

      > .h-stack {
        justify-content: flex-start;
      }
    }
  }
}
