.icon-header {
  display: flex;
  align-items: center;

  &__inner {
    display: flex;
    align-items: center;
  }

  .icon + .title {
    margin-left: var(--g-xxs);
  }

  .label {
    line-height: var(--icon-diameter);
    white-space: nowrap;
  }

  &--label-left {
    .icon {
      order: 2;
    }

    .title {
      order: 1;
    }

    .icon + .title {
      margin-right: var(--g-xxs);
      margin-left: 0;
    }
  }
}
