.tab {
  #{--active-indicator-color}: var(--disabled-color);

  cursor: pointer;
  display: flex;
  position: relative;
  align-items: center;
  height: var(--g-xl);
  text-decoration: none;
  //line-height: var(--g-xl);
  color: inherit;

  &__details {
    width: 100%;
  }

  &__label {
    padding: 0 var(--g-xxs);
    text-align: center;
    line-height: 1.25;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: scale(1, 0.5);
    transition: background-color var(--transition-duration) ease-in-out, transform var(--transition-duration) ease-in-out;
    background: var(--active-indicator-color);
    width: 100%;
    height: var(--border-width-thick);
    content: '';
    transform-origin: center bottom;
  }

  &:focus,
  &:hover,
  &[active] {
    #{--active-indicator-color}: var(--brand-color);

    &::after {
      transform: scale(1);
    }
  }

  &[active] {
    font-weight: var(--fw-b);
  }
}
