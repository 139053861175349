.progress {
  display: flex;
  align-items: center;
  border-bottom: var(--border-width-thin) solid var(--disabled-color);
  height: var(--g-xl);
}

@include break-at('mobile') {
  .progress {
    &__sec {
      margin: 0;
      width: 100%;

      > .h-stack {
        justify-content: center;
        width: 100%;
      }
    }
  }
}
