@charset 'UTF-8';

// 1. Configuration and helpers
@import "abstracts/variables", "abstracts/functions", "abstracts/mixins";

// 2. Base stuff
@import "base/reset", "base/base", "base/fonts", "base/typography", "base/helpers";

// 3. Layout
@import "layout/container", "layout/grid", "layout/list", "layout/stack";

// 4. Components
@import // Atoms
  "components/atoms/button",
  "components/atoms/chip", "components/atoms/counter", "components/atoms/dropdown", "components/atoms/field",
  "components/atoms/icon-button", "components/atoms/icon-chip", "components/atoms/icon-header",
  "components/atoms/icon-label", "components/atoms/icon", "components/atoms/image", "components/atoms/logo",
  "components/atoms/switch", "components/atoms/tab",
  // Molecules
  "components/molecules/company-details",
  "components/molecules/company-upload", "components/molecules/dialog", "components/molecules/info-side",
  "components/molecules/item", "components/molecules/popup", "components/molecules/progress",
  "components/molecules/progress-nav", "components/molecules/search-result-row", "components/molecules/tab-navigation",
  "components/molecules/user", "components/molecules/user-popup", "components/molecules/credit-history",
  // Organisms
  "components/organisms/cart",
  "components/organisms/footer", "components/organisms/menu", "components/organisms/nav",
  "components/organisms/search-form", "components/organisms/user-form";

.popup__popup {
  grid-column-end: 9;
  grid-column-start: 5;
}

@include break-at("tablet") {
  .popup__popup {
    grid-column-end: 9;
    grid-column-start: 1;
  }
}
@include break-at("mobile") {
  .popup-dialog,
  .register-popup-dialog {
    > div {
      width: 100% !important;
    }
  }

  .register-popup-dialog {
    .terms-and-conditions {
      max-height: 300px;
    }
  }

  .popup__popup {
    grid-column-end: 9;
    grid-column-start: 1;
  }
}
