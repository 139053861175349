.item {
  display: flex;
  position: relative;
  transition: background-color var(--transition-duration) ease-in-out;
  padding-left: var(--g-xxs);
  height: var(--g-xl);
  overflow: hidden;

  > .h-stack {
    flex: 1;
  }

  &.without-overflow {
    overflow: initial;
  }

  .col {
    flex-grow: 0;
    flex-shrink: 0;
    white-space: nowrap;

    a {
      text-decoration: none;
      color: inherit;
    }

    &--name {
      flex-basis: calc(25% - var(--g-xl));
    }

    &--icon {

      .download {
        display: flex;
        align-items: flex-end;
      }
    }

    &--arrow {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;

      .icon {
        margin-right: 45px;
        transform: translateX(var(--g-s));
        transition: transform var(--transition-duration) ease-in-out;
      }
    }
  }

  &:focus-within,
  &:hover {
    background: var(--element-hover-color);

    .col {
      &--arrow {
        .icon {
          transform: translateX(0);
        }
      }
    }
  }

  &[blurred] {
    pointer-events: none;
    filter: blur(var(--g-xxs));
  }

  &--crt {
    .col--name,
    .col--data {
      flex: 1;
    }
  }

  &--com {
    padding: var(--g-s);
    height: auto;

    .col--tags {
      display: flex;
      flex-basis: calc(25%);
      justify-content: flex-end;
    }

    .col--country {
      min-width: 435px;
    }

    .col--name,
    .col--data {
      min-width: 390px;
    }
  }

  &--scs {
    padding: var(--g-s);
    height: auto;

    .col--tags {
      flex-basis: calc(50% - var(--g-xs));

      .parameters {
        flex-wrap: wrap;
        margin-bottom: calc(-1 * var(--space));

        li {
          margin-right: var(--space);
          margin-bottom: var(--space);
          margin-left: 0;
        }
      }
    }

    .col--actions {
      flex-basis: calc(25% - var(--g-xs));
    }
  }

  &--fin {
    .col--chip-label,
    .col--text {
      flex-basis: calc(25% - var(--g-xs));
    }
  }

  &--doc,
  &--mgm {
    .col--tags {
      flex: 1;
    }
  }

  &--def {
    height: auto;

    .value {
      display: block;
      line-height: var(--icon-diameter);
    }

    &:hover {
      background: transparent;
    }
  }

  &--usr {
    .col {
      &--icon,
      &--name {
        display: flex;
        justify-content: center;
      }

      &--image {
        align-items: center;

        .icon {
          border: var(--border-width) solid var(--text-alt-color);
          border-radius: 100%;
          background: $toggle-selected-color;
        }
      }

      &--name {
        flex: 1;
        flex-direction: column;
      }

      &.vertical {
        align-items: center;
        padding: 5px;

        > button {
          margin: 5px;
        }
      }
    }

    &.company-invite {
      padding: 10px;
      height: initial;
    }
  }
}

@include break-at("tablet") {
  .item {
    > .h-stack {
      flex-wrap: wrap;
    }

    &:focus-within,
    &:hover {
      background: transparent;
    }

    &--com,
    &--scs {
      padding-right: 0;
      padding-left: 0;

      .col {
        &--name {
          flex-basis: 100%;
        }

        &--icon {
          display: none;
        }
      }
    }

    &--com {
      .col--country {
        min-width: initial;
      }

      .col--name,
      .col--data {
        min-width: initial;
      }
      .col {
        &--data {
          flex-basis: 100%;
          margin-top: var(--space);
          margin-left: 0;
          min-width: initial;

          .icon {
            flex: 0 0 var(--icon-diameter);
          }

          .label {
            white-space: normal;
          }
        }

        &--tags {
          position: absolute;
          top: var(--g-s);
          right: var(--g-s);
        }
      }
    }
  }
}

@include break-at("mobile") {
  .item {
    &:focus-within,
    &:hover {
      background: transparent;
    }

    .col--country {
      min-width: initial;
    }

    .col--name,
    .col--data {
      min-width: initial;
    }

    .col {
      &--tags {
        flex-wrap: wrap;
      }

      &--arrow {
        display: none;
      }
    }

    &--com {
      .col {
        order: 2;

        &--tags {
          position: relative;
          top: 0;
          right: 0;
          order: 1;
          margin-bottom: var(--space);
          margin-left: 0;
        }
      }
    }

    &--fin {
      padding: var(--g-xs) 0;
      height: auto;

      > .h-stack {
        align-items: flex-start;
      }
    }

    &--mgm {
      padding: var(--g-xs) 0;
      height: auto;

      > .h-stack {
        align-items: flex-start;
      }
    }

    &--doc {
      padding: var(--g-xs) 0;
      height: auto;

      > .h-stack {
        align-items: flex-start;
      }

      .col {
        &--icon {
          display: none;
        }
      }
    }

    &--scs {
      .col {
        &--name,
        &--tags,
        &--actions {
          flex: 0 0 100%;
        }

        &--tags,
        &--actions {
          margin-top: var(--space);
          margin-left: 0;
        }

        &--actions {
          > .stack {
            align-items: flex-start;
          }
        }
      }
    }
  }
}
