.cart {
  border-left: var(--border-width-thin) solid var(--disabled-color);
  padding-left: var(--grid-gap);

  &__title {
    .label {
      font-size: var(--title-m-fs);
      font-weight: var(--fw-sb);
    }
  }

  &__items {
    .item {
      line-height: var(--g-xl);
    }
  }

  &__details {
    text-align: right;
  }

  &__credits {
    justify-content: flex-end;

    .label {
      font-size: var(--title-m-fs);
      font-weight: var(--fw-sb);
    }
  }

  .cart-status {
    position: relative;
    border: var(--border-width) solid var(--disabled-color);
    border-radius: var(--border-radius);
    padding: var(--g);

    .icon-button {
      position: absolute;
      top: var(--g-xs);
      right: var(--g-xs);
    }

    .icon-label {
      .icon {
        color: var(--success-color);
      }

      .label {
        font-size: var(--title-m-fs);
        font-weight: var(--fw-sb);
      }
    }
  }

  &--empty {
    .cart__content {
      text-align: center;
      color: var(--disabled-color);
    }

    .cart__image {
      align-self: flex-end;
      width: 60%;

      img {
        display: block;
        width: 100%;
      }
    }
  }
}
