.list {
  > li {
    transition: background-color var(--transition-duration) ease-in-out;

    &:hover {
      background: var(--element-hover-color);
    }
  }

  > li + li {
    border-top: var(--border-width-thin) solid var(--disabled-color);
  }
}
