.privacy-policy {
    padding-top: 2rem;
    b {
        font-weight: bold;
    }

    ul {
        list-style: disc;
    }
}
