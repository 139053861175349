.company-details {
  border-left: var(--border-width-thick) solid var(--text-color);
  padding-left: var(--g);
  box-sizing: border-box;
}

@include break-at('mobile') {
  .company-details {
    .actions {
      align-items: flex-start;
      margin-top: var(--g);
      border-top: var(--border-width-thin) solid var(--disabled-color);
      padding-top: var(--g);
    }
  }
}
