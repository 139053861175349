.icon-chip {
  #{--background-color}: var(--brand-color);

  display: inline-block;
  cursor: pointer;
  border-radius: var(--g-xxs);
  background: var(--background-color);
  padding-right: var(--g-xs);
  padding-left: var(--g-xs);

  &__inner {
    display: flex;
    align-items: center;
  }

  .label {
    line-height: var(--icon-diameter);
    white-space: nowrap;
    font-size: var(--annotation-fs);
    font-weight: var(--fw-sb);
    display: flex;
  }

  .label + .icon {
    margin-left: var(--g-xxs);
  }

  &--label-right {
    .label {
      order: 2;
    }

    .icon {
      order: 1;
    }

    .label + .icon {
      margin-right: var(--g-xxs);
      margin-left: 0;
    }
  }
}
