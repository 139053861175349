// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
// Hide element while making it readable for screen readers
// Shamelessly borrowed from HTML5Boilerplate:
// https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
// -----------------------------------------------------------------------------
.visually-hidden {
  position: absolute;
  margin: -0.0625rem;
  border: 0;
  padding: 0;
  width: 0.0625rem;
  height: 0.0625rem;
  overflow: hidden;
  clip: rect(0 0 0 0);
}

/// -----------------------------------------------------------------------------
/// Desktop helpers
/// -----------------------------------------------------------------------------
@media screen and (min-width: map-get($breakpoints, "tablet")) {
  .no-desktop {
    display: none !important; // sass-lint:disable-line no-important
  }
}

@media (max-width: 1023px) {
  .no-tablet {
    display: none !important; // sass-lint:disable-line no-important
  }
}

@media (min-width: 1024px) and (max-width: 1279px) {
  .gravity_menu  {
    font-size: 14px;
  }
  .item--crt {
    font-size: 13px;
  }
  .btn-res {
    font-size: 16px;
  }
}

@media (min-width: 1024px) and (max-width: 1060px) {
  .gravity_menu  {
    font-size: 13px;
  }
  .btn-res {
    font-size: 14px;
  }
}

/// -----------------------------------------------------------------------------
/// Mobile helpers
/// -----------------------------------------------------------------------------
@include break-at("mobile") {
  .no-mobile {
    display: none !important; // sass-lint:disable-line no-important
  }

  .user-popup {
    overflow-y: scroll;
    max-height: 70vh;
  }

  .cart__content {
    font-size: 14px;
  }
  .btn-res {
    font-size: 14px;
  }
}
