.counter {
  #{--background-color}: var(--disabled-color);
  #{--color}: var(--text-alt-color); // sass-lint:disable-line no-duplicate-properties

  display: inline-block;
  border-radius: var(--border-radius);
  background: var(--background-color);
  padding-right: var(--g-xxxs);
  padding-left: var(--g-xxxs);
  min-width: var(--counter-diameter);
  height: var(--counter-diameter);
  text-align: center;
  line-height: var(--counter-diameter);
  color: var(--color);
  font-size: var(--annotation-fs);
  font-weight: var(--fw-b);

  &--rounded {
    border-radius: 100%;
    padding-top: 2px;
    padding-right: 6px;
  }
}
