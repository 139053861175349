// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------


:root {
  // NOTE: sass-lint nem okos, ha CSS változókról van szó, itt pedig van pár belőle
  // sass-lint:disable-block no-duplicate-properties

  // Gutter
  #{--g-xxxs}: #{$gutter-xxxs};
  #{--g-xxs}: #{$gutter-xxs};
  #{--g-xs}: #{$gutter-xs};
  #{--g-s}: #{$gutter-s};
  #{--g}: #{$gutter};
  #{--g-l}: #{$gutter-l};
  #{--g-xl}: #{$gutter-xl};

  #{--border-width}: #{$border-width};
  #{--border-width-thick}: #{$border-width-thick};
  #{--border-width-thin}: #{$border-width-thin};
  #{--border-radius}: #{$border-radius};

  #{--transition-duration}: #{$transition-duration};

  // Colors

  #{--brand-color}: #{$accent-color};
  #{--toggle-selected}: #{$toggle-selected-color};
  #{--text-color}: #{$text-color};
  #{--text-alt-color}: #{$text-alt-color};
  #{--disabled-color}: #{$disabled-color};
  #{--success-color}: #{$success-color};
  #{--warning-color}: #{$warning-color};
  #{--error-color}: #{$error-color};
  #{--error-background-color}: #{$error-background-color};
  #{--element-hover-color}: #{$element-hover-color};
  #{--page-background-color}: #{$page-background-color};
  #{--profile-chip-color}: #{$profile-chip-color};

  // Font sizes

  #{--title-xl-fs}: #{$title-xl-fs};
  #{--title-l-fs}: #{$title-l-fs};
  #{--title-m-fs}: #{$title-m-fs};
  #{--title-s-fs}: #{$title-s-fs};
  #{--body-fs}: #{$body-fs};
  #{--button-fs}: #{$button-fs};
  #{--annotation-fs}: #{$annotation-fs};

  // Font weights

  #{--fw-l}: #{$fw-l};
  #{--fw-n}: #{$fw-n};
  #{--fw-sb}: #{$fw-sb};
  #{--fw-b}: #{$fw-b};
  #{--fw-el}: #{$fw-el};

  // Line heights
  // Layout
  // Main
  #{--main-pt}: calc(var(--nav-height) + var(--g-xl));
  #{--main-pb}: var(--g-xl);
  // Container
  #{--container-margin}: #{$container-margin-desktop};
  // Grid
  #{--grid-columns}: #{$grid-columns-desktop};
  #{--data-grid-columns}: #{$data-grid-columns-desktop};
  #{--grid-gap}: #{$grid-gap-desktop};
  // Components
  #{--field-border-color}: var(--disabled-color);
  #{--field-focus-border-color}: var(--text-color);
  #{--field-height}: #{$field-height};
  #{--field-multiline-height}: #{$field-multiline-height};
  #{--field-background-color}: var(--text-alt-color);
  #{--field-disabled-background-color}: #{$field-disabled-background-color};
  #{--field-color}: var(--text-color);
  #{--field-placeholder-color}: var(--disabled-color);


  // Atoms
  // Chip
  #{--chip-height}: #{$chip-height};

  // Counter
  #{--counter-diameter}: #{$counter-diameter};

  // Icon
  #{--icon-diameter}: #{$icon-diameter};
  #{--icon-s-diameter}: #{$icon-s-diameter};
  #{--icon-l-diameter}: #{$icon-l-diameter};

  // Logo
  #{--logo-s-width}: #{$logo-s-width};
  #{--logo-s-height}: #{$logo-s-height};
  #{--logo-full-width}: #{$logo-full-width};
  #{--logo-full-height}: #{$logo-full-height};

  // Molecules
  // Organisms

  // Footer
  #{--footer-height}: #{$footer-height};

  // Nav
  #{--nav-height}: #{$nav-height};

  // Menu
  #{--menu-box-shadow}: #{$menu-box-shadow};

  // Pages

  // Home
  #{--about-offset}: #{$about-offset};
}

@include break-at('tablet') {
  :root {
    // NOTE: sass-lint nem okos, ha CSS változókról van szó, itt pedig van pár belőle
    // sass-lint:disable-block no-duplicate-properties

    // Font sizes

    #{--title-xl-fs}: #{$title-xl-fs-tablet};
    #{--title-l-fs}: #{$title-l-fs-tablet};
    #{--title-m-fs}: #{$title-m-fs-tablet};
    #{--title-s-fs}: #{$title-s-fs-tablet};
    #{--body-fs}: #{$body-fs-tablet};
    #{--button-fs}: #{$button-fs-tablet};
    #{--annotation-fs}: #{$annotation-fs-tablet};

    // Layout
    // Container
    #{--container-margin}: #{$container-margin-tablet};
    // Grid
    #{--grid-columns}: #{$grid-columns-tablet};
    #{--data-grid-columns}: #{$data-grid-columns-tablet};
    #{--grid-gap}: #{$grid-gap-tablet};
  }
}

@include break-at('mobile') {
  :root {
    // NOTE: sass-lint nem okos, ha CSS változókról van szó, itt pedig van pár belőle
    // sass-lint:disable-block no-duplicate-properties

    // Layout
    // Container
    #{--container-margin}: #{$container-margin-mobile};
    // Grid
    #{--grid-columns}: #{$grid-columns-mobile};
    #{--data-grid-columns}: #{$data-grid-columns-mobile};
    #{--grid-gap}: #{$grid-gap-mobile};
    // Components
    // Organisms
    // Nav
    #{--nav-height}: #{$nav-m-height};
  }
}

// Set up a decent box model on the root element
html {
  box-sizing: border-box;
}

// -----------------------------------------------------------------------------
// Make all elements from the DOM inherit from the parent box-sizing
// Since `*` has a specificity of 0, it does not override the `html` value
// making all elements inheriting from the root box-sizing value
// See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
// -----------------------------------------------------------------------------
*,
*::before,
*::after {
  box-sizing: inherit;
}

$home-credits-width: 16.777%;
$home-credits-width-tablet: 9.896%;
$home-credits-width-mobile: 23.75%;

.main {
  background: var(--page-background-color);
  padding-top: var(--main-pt);
  padding-bottom: var(--main-pb);
  #{--min-height}: calc(100vh - var(--footer-height));
  min-height: var(--min-height);

  .section {
    &--header {
      margin-top: calc(-1 * var(--main-pt));
      padding-top: var(--main-pt);
      padding-bottom: var(--main-pb);
      color: var(--text-alt-color);
    }
  }

  &--home {
    #{--credits-width}: $home-credits-width;

    .section {
      &--header {
        background: var(--text-color) url('../../../public/assets/img/header-home.png') no-repeat center / cover;

        .credits {
          position: relative;
          flex: 0 0 var(--credits-width);

          &__inner {
            padding-top: 92%;
            height: 0;
          }

          svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      &--about {
        .section-content,
        .section-image {
          margin-top: calc(-1 * var(--about-offset));
        }

        .section-content {
          padding-top: calc(var(--about-offset) + var(--g-xl));
        }
      }
    }
  }

  &--search {
    .section {
      &--header {
        background: var(--text-color) url('../../../public/assets/img/header-search.png') no-repeat center / cover;
      }

      &--results {
        padding-top: var(--g-xl);
      }
    }
  }

  &--user-form {
    background: var(--text-color) url('../../../public/assets/img/header-home.png') no-repeat center / cover;
  }

  &--error {
    #{--min-height}: calc(100vh - var(--footer-height));
    min-height: var(--min-height);
  }

  &--company-workspace {
    .quantity {
      flex: 1;

      & + .annotation {
        flex: 2;
        line-height: var(--field-height);
      }
    }

    .col {
      &--center,
      &--right {
        border-left: var(--border-width-thin) solid var(--disabled-color);
        padding-left: var(--grid-gap);
      }

      &--center {
        hr {
          margin-left: 0;
          width: 60%;
        }
      }
    }
  }
}

@include break-at('tablet') {
  .main {
    &--home {
      #{--credits-width}: $home-credits-width-tablet;

      .steps {
        .text {
          margin-right: auto;
          margin-left: auto;
          width: 75%;
          text-align: center;
        }
      }
    }
  }
}

@include break-at('mobile') {
  .main {
    &--home {
      #{--credits-width}: $home-credits-width-mobile;

      .steps {
        .text {
          width: 100%;
        }
      }
    }

    &--company-profile {
      .cp-header {
        > .h-stack {
          align-items: flex-end;
        }
      }
    }

    &--company-workspace {
      .col {
        &--center,
        &--right {
          border-top: var(--border-width-thin) solid var(--disabled-color);
          border-left: 0;
          padding-top: var(--grid-gap);
          padding-left: 0;
        }
      }
    }
  }
}


// TODO: organisms/results
@include break-at('tablet') {
  .results {
    &__sort {
      > .h-stack {
        justify-content: space-between;
      }
    }
  }
}


hr {
  border: 0;
  background: var(--disabled-color);
  width: 100%;
  height: 0.0625rem;
}
