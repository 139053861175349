.search-result-row {
  #{--parameters-width}: 50%;

  display: flex;
  padding: var(--g);

  &__details {
    // NOTE: iefix - Ha flex-basisban calc szerepel, nem lehet flex shorthandet használni
    flex-basis: calc((100% - var(--parameters-width)) / 2);
    flex-grow: 0;
    flex-shrink: 0;
  }

  &__parameters {
    // NOTE: iefix - Biztos, ami biztos...
    flex-basis: var(--parameters-width);
    flex-grow: 0;
    flex-shrink: 0;

    .parameters {
      flex-wrap: wrap;
      margin-top: calc(-1 * var(--space));

      > li {
        margin-top: var(--space);
        margin-right: var(--space);
        margin-left: 0;
      }
    }
  }

  &__actions {
    // NOTE: iefix - Ha flex-basisban calc szerepel, nem lehet flex shorthandet használni
    display: flex;
    flex-basis: calc((100% - var(--parameters-width)) / 2);
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
  }
}
