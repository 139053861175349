// --------------------------------------------------------------------------
// NOTE:
// A [hover], [focus], [active] atttribútumok azért szerepelnek, hogy a story
// boardban látható legyen az állapotuk. Ezek később törölhetők.
// Természetesen a :hover, :focus, :active állapotoknak maradniuk kell!
// --------------------------------------------------------------------------

.button {
  #{--button-border-color}: var(--brand-color);
  #{--button-background-color}: var(--brand-color); // sass-lint:disable-line no-duplicate-properties
  #{--button-color}: var(--text-color); // sass-lint:disable-line no-duplicate-properties

  display: inline-block;
  transition: border-color var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
  border: var(--border-width) solid var(--button-border-color);
  border-radius: var(--border-radius);
  background: var(--button-background-color);
  cursor: pointer;
  padding-right: var(--g-xs);
  padding-left: var(--g-xs);
  height: var(--field-height);
  line-height: calc(var(--field-height) - (2 * var(--border-width)));
  color: var(--button-color);
  font-size: var(--button-fs);
  font-weight: var(--fw-sb);
  box-sizing: border-box;

  &__inner {
    display: flex;
    align-items: center;
    text-align: center;

    span {
      flex: 1;
      white-space: nowrap;
    }
  }

  &[focus],
  &:focus,
  &[hover],
  &:hover {
    #{--button-border-color}: #{$button-hover-background-color};
    #{--button-background-color}: #{$button-hover-background-color}; // sass-lint:disable-line no-duplicate-properties
  }

  &[active],
  &:active {
    #{--button-border-color}: var(--brand-color);
    #{--button-background-color}: var(--brand-color); // sass-lint:disable-line no-duplicate-properties
  }

  &[disabled] {
    #{--button-border-color}: var(--disabled-color);
    #{--button-background-color}: var(--disabled-color); // sass-lint:disable-line no-duplicate-properties
    #{--button-color}: #{$button-disabled-color}; // sass-lint:disable-line no-duplicate-properties

    cursor: not-allowed;
  }

  &--secondary {
    #{--button-background-color}: var(--text-alt-color);

    &[disabled] {
      #{--button-border-color}: var(--disabled-color);
      #{--button-background-color}: var(--text-alt-color); // sass-lint:disable-line no-duplicate-properties
    }
  }

  &--neutral {
    #{--button-background-color}: var(--text-alt-color);
    #{--button-border-color}: var(--text-alt-color); // sass-lint:disable-line no-duplicate-properties

    &[focus],
    &:focus,
    &[hover],
    &:hover,
    &[active],
    &:active {
      #{--button-border-color}: #{$element-hover-color};
      #{--button-background-color}: #{$element-hover-color}; // sass-lint:disable-line no-duplicate-properties
    }

    &[disabled] {
      #{--button-background-color}: var(--text-alt-color);
      #{--button-border-color}: var(--text-alt-color); // sass-lint:disable-line no-duplicate-properties
    }
  }

  &--terminate {
    #{--button-border-color}: #{$error-color};
    #{--button-background-color}: var(--text-alt-color); // sass-lint:disable-line no-duplicate-properties
    #{--button-color}: #{$error-color}; // sass-lint:disable-line no-duplicate-properties

    &[focus],
    &:focus,
    &[hover],
    &:hover,
    &[active],
    &:active {
      #{--button-border-color}: var(--error-color);
      #{--button-background-color}: var(--error-background-color); // sass-lint:disable-line no-duplicate-properties
      #{--button-color}: var(--error-color); // sass-lint:disable-line no-duplicate-properties
    }

    &[disabled] {
      #{--button-border-color}: var(--error-color);
      #{--button-background-color}: var(--text-alt-color); // sass-lint:disable-line no-duplicate-properties
      #{--button-color}: var(--error-background-color); // sass-lint:disable-line no-duplicate-properties
    }
  }
}
