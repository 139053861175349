.dialog {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;

  &__overlay,
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    opacity: 0.5;
    z-index: 1;
    background: var(--text-color);
  }

  &__content {
    display: flex;
    z-index: 2;

    .container {
      display: flex;
      width: 100%;
    }

    .gi-4 {
      display: flex;
      align-items: center;
    }
  }

  &__dialog {
    border: var(--border-width) solid var(--disabled-color);
    border-radius: var(--border-radius);
    background: var(--text-alt-color);
    padding: var(--g);
    width: 100%;
    box-sizing: border-box;
  }
}
