.billing-summary {
  p {
    display: flex;
    margin-bottom: 5px;

    span.fill {
      flex: 1;
      margin: 0 10px;

      background-image: linear-gradient(to right, black 33%, rgba(255, 255, 255, 0) 0%);
      background-position: center;
      background-size: 10px 2px;
      background-repeat: repeat-x;
    }
  }
}

.checkout-container {
  text-align: center;
}

.ip-popup {
  text-align: center;
}