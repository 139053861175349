/// Light
@font-face {
  font-family: 'Space Grotesk';
  font-weight: 300;
  src: url('../../../public/assets/fonts/sg-l.woff'), url('../../../public/assets/fonts/sg-l.woff2');
}

/// Regular
@font-face {
  font-family: 'Space Grotesk';
  font-weight: 400;
  src: url('../../../public/assets/fonts/sg-r.woff'), url('../../../public/assets/fonts/sg-r.woff2');
}

/// SemiBold
@font-face {
  font-family: 'Space Grotesk';
  font-weight: 600;
  src: url('../../../public/assets/fonts/sg-sb.woff'), url('../../../public/assets/fonts/sg-sb.woff2');
}

/// Bold
@font-face {
  font-family: 'Space Grotesk';
  font-weight: 700;
  src: url('../../../public/assets/fonts/sg-b.woff'), url('../../../public/assets/fonts/sg-b.woff2');
}
