.grid,
.data-grid {
  display: grid;
  width: 100%;
  grid-gap: var(--grid-gap);
  box-sizing: border-box;
  grid-template-columns: repeat(var(--grid-columns), 1fr);
}

@for $i from 1 through $grid-columns-desktop {
  .gi-#{$i} {
    grid-column-end: span #{$i};
  }
  .gi-s-#{$i} {
    grid-column-start: #{$i};
  }
  .gi-e-#{($i + 1)} {
    grid-column-end: #{($i + 1)};
  }
}

@include break-at("tablet") {
  @for $i from 1 through $grid-columns-tablet {
    .t-gi-#{$i} {
      grid-column-end: span #{$i};
    }
    .t-gi-s-#{$i} {
      grid-column-start: #{$i};
    }
    .t-gi-e-#{($i + 1)} {
      grid-column-end: #{($i + 1)};
    }
  }
}

@include break-at("mobile") {
  @for $i from 1 through $grid-columns-mobile {
    .m-gi-#{$i} {
      grid-column-end: span #{$i};
    }
    .m-gi-s-#{$i} {
      grid-column-start: #{$i};
    }
    .m-gi-e-#{($i + 1)} {
      grid-column-end: #{($i + 1)};
    }
  }

  .m-gi-0 {
    display: none;
  }
}

@include break-at("mobile") {
  .credit-reward {
    .gi-3 {
      grid-column-end: span 9;
    }
  }
}

.data-grid {
  grid-gap: var(--g-xs);
  grid-template-columns: repeat(var(--data-grid-columns), 1fr);

  &.full-width {
    text-align: justify;
    grid-template-columns: 100%;
  }
}

.space {
  li {
    margin-bottom: 15px;
  }
}
