.logo {
  #{--logo-width}: var(--logo-s-width);
  #{--logo-height}: var(--logo-s-height); // sass-lint:disable-line no-duplicate-properties

  width: var(--logo-width);
  height: var(--logo-height);

  &__logo {
    width: var(--logo-width);
    height: var(--logo-height);
  }

  &--full {
    #{--logo-width}: var(--logo-full-width);
    #{--logo-height}: var(--logo-full-height); // sass-lint:disable-line no-duplicate-properties
  }

  &--home-header {
    #{--logo-width}: calc(2 * var(--logo-full-width));
    #{--logo-height}: calc(2 * var(--logo-full-height)); // sass-lint:disable-line no-duplicate-properties
  }

  &--error {
    #{--logo-width}: calc(1.5 * var(--logo-full-width));
    #{--logo-height}: calc(1.5 * var(--logo-full-height)); // sass-lint:disable-line no-duplicate-properties
  }
}
