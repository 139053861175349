.phone_number-container{
  display: flex;
  margin-top: var(--g-xxs);
  align-items: center;

  .country {
    width: 25%;
  }

  .telephone-number {
    width: 50%;
  }

  .area-code {
    width: 25%;
  }
}