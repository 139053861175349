.user-popup {
  display: flex;
  position: absolute;
  top: var(--g-s);
  right: var(--container-margin);
  z-index: 11;
  border: var(--border-width) solid var(--text-color);
  background: var(--text-alt-color);
  padding: var(--g-xs);
  max-width: 31.25rem;
  color: var(--text-color);

  .header {
    padding-bottom: 10px;
  }

  p {
    span {
      display: flex;
    }
  }

  hr {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }

  &[hidden] {
    display: none;
  }
}
