/* Custom properties */
:root {
  --tooltip-text-color: #011627;
  --tooltip-background-color: #ffffff;
  --tooltip-margin: 350px;
  --tooltip-height: 355px;
  --tooltip-border-color: #cfd2db;
  --tooltip-arrow-size: 6px;
  --tooltip-max-size: 600px;
  --tooltip-loading-size: 101px;
  --tooltip-small-size: 250px;
}

/* Wrapping */
.Tooltip-Wrapper {
  cursor: pointer;
  display: inline-block;
  position: relative;
}

/* Absolute positioning */
.Tooltip-Tip {
  width: var(--tooltip-max-size);
  height: var(--tooltip-height);
  overflow: auto;
  border: 2px solid var(--tooltip-border-color);
  padding: 15px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: var(--tooltip-text-color);
  background: var(--tooltip-background-color);
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 10000;
  white-space: nowrap;

  .close-icon {
    display: none;
  }
}

/* CSS border triangles */
.Tooltip-Tip::before {
  content: " ";
  left: 50%;
  border: solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-width: var(--tooltip-arrow-size);
  margin-left: calc(var(--tooltip-arrow-size) * -1);
}

/* Absolute positioning */
.Tooltip-Tip.right {
  left: calc(var(--tooltip-max-size) * 0.5);
  top: 50%;
}
/* CSS border triangles */
.Tooltip-Tip.right::before {
  left: calc(var(--tooltip-arrow-size) * -1);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-right-color: var(--tooltip-background-color);
}

@media all and (max-width: 599px), only screen and (max-device-width: 599px) {
  .Tooltip-Tip {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: none;

    &::before {
      display: none;
    }

    &.small {
      height: 100%;
      max-height: 100%;
    }
    &.right {
      left: 0;
      top: 0;
    }

    .close-icon {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      padding: 15px;
    }
  }
}

@media (min-width: 600px) {
  .Tooltip-Tip.small {
    max-height: 75px;
    bottom: initial;
    width: var(--tooltip-small-size);
    left: calc(var(--tooltip-small-size) * 0.5);
  }
}

/* Absolute positioning */
.Tooltip-Tip.top {
  top: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.top::before {
  top: 100%;
  border-top-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.bottom {
  bottom: calc(var(--tooltip-margin) * -1);
}
/* CSS border triangles */
.Tooltip-Tip.bottom::before {
  bottom: 100%;
  border-bottom-color: var(--tooltip-background-color);
}

/* Absolute positioning */
.Tooltip-Tip.left {
  left: auto;
  right: calc(100% + var(--tooltip-margin));
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
/* CSS border triangles */
.Tooltip-Tip.left::before {
  left: auto;
  right: calc(var(--tooltip-arrow-size) * -2);
  top: 50%;
  transform: translateX(0) translateY(-50%);
  border-left-color: var(--tooltip-background-color);
}

.Tooltip-Tip.isLoading {
  max-height: 75px;
  bottom: initial;
  width: initial;
  left: calc(var(--tooltip-loading-size) * 0.5);
}

.source_table {
  font-family: "Space Grotesk";
  margin-top: 10px;
}

.source_table td {
  padding-bottom: 15px;
  padding-right: 5px;
  font-weight: 100;
  text-align: left;
}

.source_table td.column-date {
  width: 120px;
  white-space: normal;
}
.source_table td.column-comment {
  width: 150px;
  white-space: normal;
}
.source_table td.column-source {
  width: 160px;
  white-space: normal;
}

.source_table th {
  padding-bottom: 15px;
  font-weight: bold;
}

.source_value {
  font-size: 16px;
  font-family: "Space Grotesk";
  font-weight: 500;
  margin-top: 5px;
}

.source_value.extended {
  white-space: normal;
}

.source_name {
  font-size: 20px;
  font-family: "Space Grotesk";
  font-weight: bold;
}

.source_wrapper {
  margin-top: 10px;
  width: 100%;
}

.source_wrapper .line {
  border-bottom: 1px solid black;
}
.source_wrapper .line td {
  padding-top: 5px;
}

.source_main {
  hr {
    background: #c4c7d6;
  }
}
