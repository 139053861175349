.nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  background: var(--text-color);
  height: var(--nav-height);
  color: var(--text-alt-color);
  font-size: var(--title-s-fs);

  .container {
    height: var(--nav-height);

    > .h-stack {
      height: var(--nav-height);
    }
  }

  .logo,
  &__items {
    transition: transform 300ms ease-in-out;
  }

  a {
    position: relative;
    text-decoration: none;

    &:not(.button):not(.icon-button) {
      line-height: var(--nav-height);
      color: inherit;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, (($button-fs / 2) + 0.125rem)) scale(0, 1);
        transition: transform 300ms ease-in-out;
        background: var(--brand-color);
        width: 100%;
        height: 0.25rem;
        content: '';
      }

      &:hover,
      &[active] {
        &::after {
          transform: translate(-50%, (($button-fs / 2) + 0.125rem)) scale(1);
        }
      }

      &[active] {
        font-weight: var(--fw-b);
      }
    }
  }

  // &[scrollable] {
  //   .no-tablet {
  //     .logo {
  //       transform: translateX(calc(-1 * (var(--container-margin) + var(--logo-full-width))));
  //     }
  //   }

  //   .nav__items {
  //     transform: translateX(calc(-1 * (var(--logo-full-width) + var(--g))));
  //   }

  //   &[scrolled] {
  //     .logo,
  //     .nav__items {
  //       transform: translateX(0);
  //     }
  //   }
  // }
}
