.image {
  display: block;
  position: relative;
  width: 100%;
  overflow: hidden;

  &__inner {
    display: block;
    height: 0;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &--about {
    &-1,
    &-2 {
      .image__inner {
        padding-top: 68%;
      }
    }

    &-3,
    &-4,
    &-5 {
      margin: auto;
      width: 86%;

      .image__inner {
        padding-top: 93%;
      }
    }
  }

  &--cart {
    width: 80%;

    .image__inner {
      padding-top: 93%;
    }
  }
}
